import React, { useCallback, useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { Card, CardContent, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../src/custom.css";
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import Dropdown from "../components/dropdown";
import { pageRoutes } from '../config/route';
import AlignHorizontalLeftRoundedIcon from '@mui/icons-material/AlignHorizontalLeftRounded';
import apiFunctions from "../apiKit/api";
import ReduxComponent from "../components/reduxData";
import TopCreators from "./topcreators/topcreator";
import { AgCharts } from "ag-charts-react";
import InterestsSharpIcon from '@mui/icons-material/InterestsSharp';

const Dashboard = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [userCount, setUserCount] = useState(0);
    const [creatorCount, setCreatorCount] = useState(0);
    const [selectedYear, setSelectedYear] = useState("");


    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    const fetchDashCounts = useCallback(async () => {
        apiFunctions.dashboardApi().then((res) => {
            if (res.status === 200) {
                console.log("count", res.data.data);
                setUserCount(res?.data?.data?.user_count);
                setCreatorCount(res?.data?.data?.creater_count);
            } else {
                console.log("Failed to fetch banners");
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        fetchDashCounts();
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [fetchDashCounts])

    const [options, setOptions] = useState({
        // Data: Data to be displayed in the chart
        data: [
            { month: "Jan", avgTemp: 2.3, iceCreamSales: 162000 },
            { month: "Mar", avgTemp: 6.3, iceCreamSales: 302000 },
            { month: "May", avgTemp: 16.2, iceCreamSales: 800000 },
            { month: "Jul", avgTemp: 22.8, iceCreamSales: 1254000 },
            { month: "Sep", avgTemp: 14.5, iceCreamSales: 950000 },
            { month: "Nov", avgTemp: 8.9, iceCreamSales: 200000 },
        ],
        // Series: Defines which chart type and data to use
        series: [{ type: "bar", xKey: "month", yKey: "iceCreamSales" }],
    });

    const handleYearchange = (e) => {
        e.preventDefault();
        setSelectedYear(e.target.value);
        if (selectedYear === "2") {
            setOptions({
                // Data: Data to be displayed in the chart
                data: [
                    { month: "Jan", avgTemp: 2.3, iceCreamSales: 162000 },
                    { month: "Feb", avgTemp: 6.3, iceCreamSales: 302000 },
                    { month: "Mar", avgTemp: 16.2, iceCreamSales: 800000 },
                    { month: "Apr", avgTemp: 22.8, iceCreamSales: 1254000 },
                    { month: "May", avgTemp: 14.5, iceCreamSales: 950000 },
                    { month: "Jun", avgTemp: 8.9, iceCreamSales: 200000 },
                    { month: "Jul", avgTemp: 2.3, iceCreamSales: 162000 },
                    { month: "Aug", avgTemp: 6.3, iceCreamSales: 302000 },
                    { month: "Sep", avgTemp: 16.2, iceCreamSales: 800000 },
                    { month: "Oct", avgTemp: 22.8, iceCreamSales: 1254000 },
                    { month: "Nov", avgTemp: 14.5, iceCreamSales: 950000 },
                    { month: "Dec", avgTemp: 8.9, iceCreamSales: 200000 }
                ],
                series: [{ type: "bar", xKey: "month", yKey: "iceCreamSales" }],
            })
        } else {
            setOptions({
                // Data: Data to be displayed in the chart
                data: [
                    { month: "Jan", avgTemp: 2.3, iceCreamSales: 162000 },
                    { month: "Mar", avgTemp: 6.3, iceCreamSales: 302000 }
                ],
                series: [{ type: "bar", xKey: "month", yKey: "iceCreamSales" }],
            })
        }

    }

    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className='row'>
                            <div className="d-flex w-100 justify sticky-top">
                                <IconButton className="web-btn" onClick={handleOpen} >
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown />
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <>
                                <div className="row p-lg-5 p-3 mt-lg-4 mt-3 main">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <Card elevation={5} className="dash_count mb-3">
                                            <Link to={pageRoutes.users} style={{ textDecoration: "none", color: "white" }} >
                                                <CardContent>
                                                    <h5 className="dash_count_text">Users</h5>
                                                    <div className="d-flex justify">
                                                        <PeopleRoundedIcon color="white" />
                                                        <h3 className="dash_count_text">{userCount}</h3>
                                                    </div>
                                                </CardContent>
                                            </Link>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <Card elevation={5} className="dash_count mb-3">
                                            <Link to={pageRoutes.creators} style={{ textDecoration: "none", color: "white" }} >
                                                <CardContent>
                                                    <h5 className="dash_count_text">Creators</h5>
                                                    <div className="d-flex justify">
                                                        <AlignHorizontalLeftRoundedIcon />
                                                        <h3 className="dash_count_text">{creatorCount}</h3>
                                                    </div>
                                                </CardContent>
                                            </Link>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <Card elevation={5} className="dash_count mb-3">
                                            <Link to={pageRoutes.interests} style={{ textDecoration: "none", color: "white" }} >
                                                <CardContent>
                                                    <h5 className="dash_count_text">Interests</h5>
                                                    <div className="d-flex justify">
                                                        <InterestsSharpIcon />
                                                        <h3 className="dash_count_text">45</h3>
                                                    </div>
                                                </CardContent>
                                            </Link>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row p-lg-5 p-3 main">
                                    <TopCreators />
                                </div>
                                <div className="row p-lg-5 p-3 main">
                                    <Card className="mt-3">
                                        <CardContent>
                                            <div className="d-flex justify">
                                                <h5><b>Users</b></h5>
                                                <select value={selectedYear} onChange={handleYearchange} className="form-select" style={{ width: "200px" }}>
                                                    <option value="">choose year</option>
                                                    <option value='1'>2000-2010</option>
                                                    <option value='2'>2010-2020</option>
                                                    <option value='3'>2020-2030</option>
                                                </select>
                                            </div>
                                            <AgCharts options={options} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </>
                        }

                    </div>
                </div>

            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default Dashboard;