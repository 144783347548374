import React, { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar";
import { Button, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Switch } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "../../../src/custom.css";
import Table from "../../components/Table";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import apiFunctions from "../../apiKit/api";
import { fetchAPIs } from "../../redux/slice/slice";
import ReduxComponent from "../../components/reduxData";
import PostTable from "../../components/postTable";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
const TopCreators = () => {
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isNewModalOpen, setIsNewModalOpen] = useState(false);
    const [isCreatorModalOpen, setIsCreatorModalOpen] = useState(false);
    const [userAcceptModalOpen, setUserAcceptModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState({ username: '', email: '', phone: '' });
    const [filteredRows, setFilteredRows] = useState([]);
    const { creatordata, topCreatordata } = useSelector((state) => state.api);
    const [IsviewAll, setIsviewAll] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {      
        console.log("redux creator data", creatordata, topCreatordata);
        setIsviewAll(false);
    }, [creatordata]);


    useEffect(() => {
        // dispatch(fetchAPIs());
        const newFilteredRows = creatordata?.map(row => ({
            ...row,
            username: row.username || null,
            email: row.email || null,
            phone: row.phone || null
        })).filter(row => {
            return (
                (filter.username === '' || row.username?.toLowerCase().includes(filter.username.toLowerCase())) &&
                (filter.email === '' || row.email?.toLowerCase().includes(filter.email.toLowerCase())) &&
                (filter.phone === '' || row.phone?.toString().includes(filter.phone))
            );
        }) || [];
        setFilteredRows(newFilteredRows);
    }, [filter, creatordata]);




    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    // const handleEditOpen = (row) => {
    //     setSelectedRow(row);
    //     setIsEditOpen(true);
    // };

    const handleEditClose = () => {
        setIsEditOpen(false);
        setSelectedRow(null);
    };

    const handleEditSave = () => {
        // Logic to save the edited data
        setIsEditOpen(false);
        setSelectedRow(null);
        Swal.fire('', 'Saved Successfully', 'success');
    };


    const handleCreatorSwitchChange = (row) => {
        setSelectedRow(row);
        setIsCreatorModalOpen(true);
    };

    const handleUserStatus = () => {
        var json = {
            "id": selectedRow?._id,
            "user_status": selectedRow?.user_status === 0 ? 1 : 0
        }
        apiFunctions.userChangestatus(json).then((res) => {
            if (res.status === 200) {
                console.log(res, 'resss');
                setIsNewModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                setIsNewModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            setIsNewModalOpen(false);
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    }

    const handleCreatorStatus = () => {
        var json = {
            "id": selectedRow?._id,
            "creater_status": parseInt(selectedRow?.creater_status === 1 ? 2 : 1)
        }
        apiFunctions.creatorChangestatus(json).then((res) => {
            if (res.status === 200) {
                console.log(res, 'resss');
                setIsCreatorModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                setIsCreatorModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            setIsCreatorModalOpen(false);
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    }


    const handleUserApprove = (status) => {
        var json = {
            "id": selectedRow?._id,
            "status": status
        }
        apiFunctions.userApprove(json).then((res) => {
            if (res.status === 200) {
                setUserAcceptModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                setUserAcceptModalOpen(false);
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            setUserAcceptModalOpen(false);
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            sortable: false,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'username',
            headerName: 'Username',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            sortable: false,
            field: 'phone',
            flex: 1,
            headerName: 'Phone',
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'Posts',
            flex: 1,
            headerName: 'Posts',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <span style={{ textDecoration: 'underline', cursor: "pointer" }} onClick={() => viewAll(params.row)} >View</span>
                );
            }
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                const creater_status = params.row.creater_status;

                return (
                    <ButtonGroup>
                        {/* <IconButton onClick={() => handleEditOpen(params.row)}><EditIcon /></IconButton> */}
                        {/* <IconButton disabled={status !== 1} onClick={() => handleCreatorAcceptReject(params.row)}>
                            <EmailIcon color={status === 1 ? "primary" : "action"} />
                        </IconButton> */}
                        <Switch checked={creater_status === 1} disabled={creater_status === 0}
                            onChange={() => handleCreatorSwitchChange(params.row)}></Switch>
                        <IconButton onClick={() => handleViewOpen(params.row)}>
                            <VisibilityIcon />
                        </IconButton>
                    </ButtonGroup>
                );
            }
        }
    ];

    const viewAll = (row) => {
        setSelectedRow(row);
        setIsviewAll(true);   
    }

    return (
        <>
            <div className="row">
                <div className="col-12 w-100">
                    {/* Page Contents */}
                    <div>
                        {IsviewAll ?
                            // creator Posts
                            <div className="row p-3 mt-3 main">
                                <div className="text-start">
                                    {/* <h5 className="fw-bold">{selectedRow?.username ? selectedRow?.username : selectedRow?.name} Posts</h5>
                                            <br /> */}
                                    <Button className="btn mb-2" startIcon={<KeyboardBackspaceOutlinedIcon />} onClick={() => setIsviewAll(false)} >Go Back</Button>
                                </div>
                                <PostTable creatorId={selectedRow?._id} creatorName={selectedRow?.username ? selectedRow?.username : selectedRow?.name} />
                            </div>
                            :
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="text-start">
                                        <h4 className="fw-bold">Top Creators</h4>
                                    </div>
                                    <Table rows={filteredRows} columns={columns} />
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            {/* View Modal */}
            <Dialog className="p-3" open={isViewOpen} onClose={handleViewClose} fullWidth={true} maxWidth="sm" >
                <DialogTitle>Creator Detail</DialogTitle>
                <DialogContent>
                    <table className="table table">
                        <tbody>
                            <tr>
                                <td> <p><strong>ID:</strong></p></td>
                                <td> <p>{selectedRow?._id}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Name:</strong></p></td>
                                <td> <p>{selectedRow?.name ? selectedRow?.name : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Username:</strong></p></td>
                                <td> <p>{selectedRow?.username ? selectedRow?.username : "-"}</p></td>
                            </tr>
                            {/* <tr>
                                <td> <p><strong>Username:</strong></p></td>
                                <td> <p>{selectedRow?.username ? selectedRow?.username : "-"}</p></td>
                            </tr> */}
                            <tr>
                                <td> <p><strong>Email:</strong></p></td>
                                <td> <p>{selectedRow?.email ? selectedRow?.email : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Phone:</strong></p></td>
                                <td> <p>{selectedRow?.phone ? selectedRow?.phone : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>DOB:</strong></p></td>
                                <td> <p>{selectedRow?.DOB ? selectedRow?.DOB : "-"}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="btn" onClick={handleViewClose}>Close</Button>
                </DialogActions>
            </Dialog>
            {/* Edit Modal */}
            <Dialog open={isEditOpen} onClose={handleEditClose}>
                <DialogTitle>Edit Top Creators</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={selectedRow?.firstName || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, firstName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={selectedRow?.lastName || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, lastName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Age"
                        type="number"
                        fullWidth
                        value={selectedRow?.age || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, age: e.target.value })}
                    />
                </DialogContent>
                <DialogActions className="p-3">
                    <Button variant="contained" className="grey-btn" onClick={handleEditClose}>Cancel</Button>
                    <Button className="btn" variant="contained" onClick={handleEditSave}>Save</Button>
                </DialogActions>
            </Dialog>
            {/* change user status Modal */}
            <Dialog fullWidth={true} maxWidth="sm" open={isNewModalOpen} onClose={() => setIsNewModalOpen(false)}>
                <DialogTitle>{selectedRow?.user_status === 0 ? "Disable" : "Enable"}  user</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to {selectedRow?.user_status === 0 ? "disable" : "enable"} user ?</p>
                </DialogContent>
                <DialogActions>
                    <Button className="grey-btn" onClick={() => handleUserStatus()}>Yes</Button>
                    <Button className="btn" onClick={() => setIsNewModalOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>

            {/* change creator status Modal */}
            <Dialog fullWidth={true} maxWidth="sm" open={isCreatorModalOpen} onClose={() => setIsCreatorModalOpen(false)}>
                <DialogTitle>{selectedRow?.creater_status === 1 ? "Disable" : "Enable"}  Creator</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to {selectedRow?.creater_status === 1 ? "disable" : "enable"} Creator ?</p>
                </DialogContent>
                <DialogActions>
                    <Button className="grey-btn" onClick={() => handleCreatorStatus()}>Yes</Button>
                    <Button className="btn" onClick={() => setIsCreatorModalOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>

            {/* change Creator accept or reject */}
            <Dialog fullWidth={true} maxWidth="sm" open={userAcceptModalOpen} onClose={() => setUserAcceptModalOpen(false)}>
                <DialogTitle>Creator Request
                    <IconButton
                        aria-label="close"
                        onClick={() => setUserAcceptModalOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton></DialogTitle>
                <DialogContent>
                    <p>User want to join as a creator</p>
                </DialogContent>
                <DialogActions className="mb-2">
                    <Button className="btn" onClick={() => handleUserApprove('2')}>Approve</Button>
                    <Button className="grey-btn" onClick={() => handleUserApprove('3')}>Reject</Button>
                </DialogActions>
            </Dialog>
            <ReduxComponent />
        </>
    );
};

export default TopCreators;
